import styled from 'styled-components';

import { theme } from '@theme';

const { breakpoints } = theme;

export const Main = styled.div`
    /* width: 768px; */
`;

export const SliderItem = styled.div`
        height: 100vh;
        max-height: 520px;
        
        ${breakpoints.down('sm', `
            max-height: 440px;
        `)}

        & > .AnchorPicture {
            display: block;
            height: 100%;
            width: 100%;
            overflow: hidden;

            & > a.WrapPicture {
                height: 100%;
                width: 100%;
                display: flex;

                picture {
                    source,
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border: 0;
                        max-width: 100%;
                        display: inline-block;
                        vertical-align: middle;
                    }
                }
            }
        }
 
`;

export const MainHowWorks = styled.div`
    max-width: 879px;
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto;

    ${theme.breakpoints.down('md', `
        flex-direction: column;
        align-items: center;
    `)}
`;

export const BoxContainer = styled.div`
    display: flex;
    align-items: center;
    &:last-child {
        & > :last-child {
            display: none;
        }
    }

    ${theme.breakpoints.down('md', `
        flex-direction: column;
        align-items: center;
    `)}
`;

export const ChevronDiv = styled.div`
    margin-top: 30px;
    padding: 1.75rem 1.5625rem;

    ${theme.breakpoints.down('md', `
        margin-top: 0;
        flex-direction: column;
        align-items: center;
        transform: rotate(90deg);
    `)}
`;

export const MainSlider = styled.div`
    max-width: 54.9375rem;
    width: 100%;
    margin: 0 auto;
`;

export const CampaignWarning = styled.div`
    text-align: center;
    display: flex;
    justify-content: center;
    color: ${theme.color.grey[700]};
    height: 85px;
`;