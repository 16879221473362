/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, } from "react";
import { useParams } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { Main, MessageInfo } from './styles';
import { location } from "@utils";
import { useCampaign } from "@hooks";
import { CONSTANTS } from "@constants/index";

const Regulament: React.FC = () => {
    const { loadCampaignRegulation, campaignRegulation } = useCampaign();
    const { campaignPath } = useParams();
    const getSubdomain = location.getSubdomain();

    const loadRegulament = useCallback(async () => {
        await loadCampaignRegulation({
            websiteAliasCompany: getSubdomain === CONSTANTS.CAMPAIGN.LOCALHOST_DOMAIN
                ? CONSTANTS.CAMPAIGN.SUBDOMAIN : getSubdomain as string,
            websiteAliasCampaign: campaignPath,
            websiteAliasProgram: location.getFirstPathname() as string
        });
    }, [campaignPath])

    useEffect(() => {
        loadRegulament();
    }, []);

    return (
        <>
            {!campaignRegulation.isLoaded &&
                <Main>
                    <Skeleton style={{ marginBottom: '5px' }} count={5} />
                </Main>
            }
            {campaignRegulation.isLoaded && !!campaignRegulation.data &&  (
                <Main dangerouslySetInnerHTML={{ __html: campaignRegulation.data }}>
                </Main>
            )}
            {campaignRegulation.isLoaded && !(!!campaignRegulation.data) &&
                <Main>
                    <MessageInfo>
                        <I icon={['fal', 'info-circle']} />
                        {campaignRegulation.message}
                    </MessageInfo>
                </Main>
            }
        </>
    )
}

export { Regulament };