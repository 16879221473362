import { theme } from '@theme';
import styled from "styled-components";

const { shadow, color, border, zIndex } = theme;

const maxContent = (max: number) => `
    max-width: ${max}px;
    width: 100%;
    margin: 0 auto;
`;

export const Main = styled.div`
    border: .0625rem solid ${color.grey[50]};
    box-shadow: ${shadow.default};
    background-color: ${color.common.white};
    padding: 15px 30px;
    border-radius: ${border.default};
    position: relative;
    z-index: ${zIndex.min};
    ${maxContent(826)};
    height: 100%;
    max-height: 515px;
    overflow: scroll;
    overflow-x: hidden;
    word-break: break-word;
`;

export const MessageInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 16px;

    & > svg {
        font-size: 26px;
        margin-bottom: 8px;
    }
`;

export const P = styled.p`
    margin-bottom: 15px;
    font-size: 14px;
    color: ${color.grey[900]};
`;