import React, { ForwardRefExoticComponent, RefAttributes } from 'react';

import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';


import { Overlay, DialogRoot, HeaderModal, CloseButton, Title, TitleDiv, Children } from './styles';

import { ModalStaticProps, DialogProps } from '@interfaces/dialog';

const STATIC_PROPS: ModalStaticProps = {
    show: () => Boolean,
    hide: () => Boolean,
}

const Dialog: ForwardRefExoticComponent<DialogProps & RefAttributes<typeof STATIC_PROPS>> = React.forwardRef((props, ref) => {

    const { title, children, desactivateLink } = props;

    const [open, setOpen] = React.useState<boolean>(() => false);


    const handleOpen = () => {
        setOpen((prev: boolean): boolean => prev = true);
        if (!open) {
            document.body.classList.add('dialog-show');
        }
    };
    const handleClose = () => {
        setOpen((prev: boolean): boolean => prev = false);
        if (desactivateLink) desactivateLink();
        if (open === true) {
            document.body.classList.remove('dialog-show');
        }
    };

    React.useImperativeHandle(ref, () => ({
        show: () => handleOpen() as any,
        hide: () => handleClose() as any,
    }));

    return (
        <React.Fragment>

            {open && (
                <Overlay>
                    <DialogRoot>
                        <HeaderModal>
                            <TitleDiv>
                                <Title>{title}</Title>
                            </TitleDiv>
                            <CloseButton onClick={handleClose}><I icon={['far', 'times']} style={{ height: '1.5rem' }} color={'#000'} /></CloseButton>
                        </HeaderModal>
                        <Children haveTitle={title !== undefined ? true : false}>
                            {children}
                        </Children>
                    </DialogRoot>
                </Overlay>
            )}
        </React.Fragment>
    );
})

export { Dialog };