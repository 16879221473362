import styled from 'styled-components';
import { theme } from '@theme';

const { color, zIndex, breakpoints } = theme;

interface DialogProps {
    haveTitle: boolean;
}

export const Overlay = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: ${zIndex.modal};
`;

export const DialogRoot = styled.div`
    background-color: ${color.common.white};
    max-width: 620px;
    width: 100%;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.4);
    border-radius: 8px;
    position: relative;
    z-index: ${zIndex.modal + 100};
    ${breakpoints.down('md', `margin: 15px;`)}
`;

export const HeaderModal = styled.div`
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-radius: ${theme.border.default};
    z-index: ${zIndex.modal + 200};
    padding-left: 30px;
    padding-bottom: 20px;
`;

export const Title = styled.h1`
    font-size: 26px;
    color: ${theme.color.grey[900]};
    font-weight: ${theme.typography.fontWeightSemiBold};
`;

export const TitleDiv = styled.div`
   display: flex;
   align-items: end;
`;

export const CloseButton = styled.div`
    border: none;
    background: none;
    cursor: pointer;
    padding: 14px 19px;
`;

export const Children = styled.div<DialogProps>`
    height: 100%;
    padding-top: ${props => props.haveTitle ? '72px' : ''};
`;
