/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { Button, Container, LogoIcon, NavItem, Dialog, Regulament, ContactForm } from '@design-system';
import { Main, Columns, Column, Nav, Ul, HeaderSvg, Dropdown, DropdownButton } from './styles';
import { HeaderProps } from "./interfaces";
import { location } from '@utils'
import { ModalStaticProps } from '@interfaces/dialog';
import { theme as t } from "@theme";
import { useParams } from "react-router-dom";
import { useAuth, useCampaign } from "@hooks";
import { CONSTANTS } from "@constants/index";
import Cookies from 'universal-cookie';
import { UserAvatar } from "./components/user-avatar";

const externalPaths = ['terms-of-use', 'privacy-policies']

const Header: React.FC<HeaderProps> =
    ({
        logo,
        className,
        navData,
        theme,
        custom,
    }: HeaderProps) => {

        const aliasCompany = CONSTANTS.ALIAS.COMPANY(location.getSubdomain() as string);
        const aliasProgramCode = CONSTANTS.ALIAS.PROGRAM_CODE(location.getFirstPathname() as string);

        const auth = useAuth();
        const cookies = new Cookies(null, { path: '/' });
        const { color } = t;
        const { campaignPath } = useParams();
        const { participant } = useCampaign();

        const contactRef = React.useRef<ModalStaticProps>(null);
        const termsRef = React.useRef<ModalStaticProps>(null);

        const [navVisibility, setNavVisibility] = useState<boolean>(false);
        const [opacityDrop, setOpacityDrop] = useState(0);
        const [displayDrop, setDisplayDrop] = useState("none");
        const [heightDrop, setHeightDrop] = useState("0");
        const [openDrop, setOpenDrop] = useState(false);

        const [
            avatarMenu,
        ] = React.useState<null | HTMLElement>(null);
        const openAvatarMenu = Boolean(avatarMenu);

        const loadThemeObjectProps = {
            websiteAliasCompany: location.getSubdomain() === CONSTANTS.CAMPAIGN.LOCALHOST_DOMAIN ?
                CONSTANTS.CAMPAIGN.SUBDOMAIN :
                location.getSubdomain() as string,
            websiteAliasProgram: location.getFirstPathname() as string,
            websiteAliasCampaign: location.getSecondPathname() as string,
        }

        function handleClick() {
            if (!openDrop) {
                setDisplayDrop("block");
                setTimeout(() => {
                    setOpacityDrop(1);
                    setHeightDrop("90px");
                    setOpenDrop(true);
                }, 20);
            } else {
                setOpacityDrop(0);
                setHeightDrop("0");
                setTimeout(() => {
                    setDisplayDrop("none");
                    setOpenDrop(false);
                }, 500);
            }
        }

        const loadRegisterTypeInfos = async () => {
            await auth.preRegister.registerTypeInformations.load({
                websiteAliasCompany: aliasCompany,
                websiteAliasProgram: aliasProgramCode,
            });
        }

        const handleMenu = () => {
            setNavVisibility(false)
            if (!navVisibility) {
                document.body.classList.add('overflow-h');
            } else {
                document.body.classList.remove('overflow-h');
            }
        }

        const handleDialog = (theme: string, key: string) => {

            if (theme === "campaign-theme") {

                switch (key) {
                    case "regulamento":
                        termsRef.current?.show()
                        break;

                    default:
                        (() => null)();
                        break;
                }
            }
            if (theme === "hotsite-theme") {

                switch (key) {
                    case "fale-conosco":
                        contactRef.current?.show()
                        break;

                    default:
                        (() => null)();
                        break;
                }
            }
        }

        const toggleActiveLink = (id: number) => () => {
            handleMenu();
            setOpacityDrop(0);
            setHeightDrop("0");
            setTimeout(() => {
                setDisplayDrop("none");
                setOpenDrop(false);
            }, 500);
        };

        const handleNavLink = (link: string) => {
            const list = window.location.pathname.split('/')
            const path = list[list.length - 1]

            if (externalPaths.includes(path)) {
                return (`/${loadThemeObjectProps.websiteAliasProgram}${campaignPath ? '/' + loadThemeObjectProps.websiteAliasCampaign : ''}/${link}`)
            }
            return (link)

        }

        React.useEffect(() => {
            return () => {
                document.body.classList.remove('overflow-h');
            }
        }, []);


        React.useEffect(() => {
            const verifyElement = document.getElementById('sticker')
            if (verifyElement) {
                $(`#sticker`).sticky({ topSpacing: 0, zIndex: '999' });

                $('#sticker').on('sticky-start', () => $("#sticker").addClass('sticker-active'));

                $('#sticker').on('sticky-end', () => $("#sticker").removeClass('sticker-active'));
            }

        }, []);

        React.useEffect(() => {
            if (!auth.preRegister.registerTypeInformations.list.isLoaded) {
                loadRegisterTypeInfos();
            }
        }, []);

        React.useEffect(() => {
            const token = cookies.get('accessToken');
            if (!participant.list.isLoaded) {
                participant.load({ token });
            }
        }, []);


        return (
            <div>
                <Main
                    style={{ backgroundColor: custom.bgColor }}
                    className={className} id="sticker">
                    <Container className="wrapper">
                        <Columns className="columns">
                            <Column className="header-control">
                                <LogoIcon src={`${location.filesPath(logo)}`} link={campaignPath ? `/${campaignPath}` : '/'} />
                                <HeaderSvg onClick={() => {
                                    handleMenu();
                                    setNavVisibility(!navVisibility)
                                }}>
                                    <I icon={
                                        navVisibility
                                            ? ['far', 'times']
                                            : ['fas', 'bars']
                                    } size="lg" />
                                </HeaderSvg>
                            </Column>

                            <Column
                                backgroundColor={custom.bgColor}
                                className={`second-column ${navVisibility ? 'menu-mobile' : ''}`}>

                                {(!navVisibility && auth.preRegister.registerTypeInformations.list.isLoaded &&
                                    !(participant.list.isLoaded && Object.keys(participant.list.data).length > 0) &&
                                    !auth.preRegister.registerTypeInformations.list.data.preRegistration.autoGenerateAccessInfo &&
                                    auth.preRegister.registerTypeInformations.list.data.preRegistration.enabled &&
                                    auth.preRegister.registerTypeInformations.list.data.allowExternalRegistration.enabled) ? (
                                    <>
                                        <Nav className="navHeader">
                                            <Ul className="ul">
                                                {navData.slice(0, 2).map(({ id, title, link }, i) => {
                                                    const titleLower = title.replace(" ", "-").toLowerCase();

                                                    return (
                                                        <NavItem
                                                            customStyle={{
                                                                color: custom.navLinkColor,
                                                                activeColor: custom.navLinkActiveColor,
                                                            }}
                                                            key={`${title}-${i}`}
                                                            onClick={toggleActiveLink(Number(id))}
                                                            title={title}
                                                            link={link !== '#!' ? handleNavLink(link) : ''}
                                                            isActive={link !== '#!' && window.location.href.includes(link)}
                                                            actionLink={() => handleDialog(theme, titleLower)}
                                                        />
                                                    )
                                                })}
                                            </Ul>
                                        </Nav>
                                        <div style={{
                                            position: 'relative',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            marginRight: '20px'
                                        }}>
                                            <DropdownButton onClick={handleClick}>
                                                <span />
                                                <span />
                                                <span />
                                            </DropdownButton>
                                            <Dropdown display={displayDrop} height={heightDrop} opacity={opacityDrop}>
                                                {navData.slice(2).map(({ id, title, link }, i) => {
                                                    const titleLower = title.replace(" ", "-").toLowerCase();

                                                    return (
                                                        <NavItem
                                                            customStyle={{
                                                                color: custom.navLinkColor,
                                                                activeColor: custom.navLinkActiveColor,
                                                            }}
                                                            key={`${title}-${i}`}
                                                            onClick={toggleActiveLink(Number(id))}
                                                            title={title}
                                                            link={link !== '#!' ? handleNavLink(link) : ''}
                                                            isActive={link !== '#!' && window.location.href.includes(link)}
                                                            actionLink={() => handleDialog(theme, titleLower)}
                                                        />
                                                    )
                                                })}
                                            </Dropdown>
                                        </div>
                                    </>
                                ) :
                                    <Nav className="navHeader">
                                        <Ul className="ul">
                                            {navData.map(({ id, title, link }, i) => {
                                                const titleLower = title.replace(" ", "-").toLowerCase();

                                                return (
                                                    <NavItem
                                                        customStyle={{
                                                            color: custom.navLinkColor,
                                                            activeColor: custom.navLinkActiveColor,
                                                        }}
                                                        key={`${title}-${i}`}
                                                        onClick={toggleActiveLink(Number(id))}
                                                        title={title}
                                                        link={link !== '#!' ? handleNavLink(link) : ''}
                                                        isActive={link !== '#!' && window.location.href.includes(link)}
                                                        actionLink={() => handleDialog(theme, titleLower)}
                                                    />
                                                )
                                            })}
                                        </Ul>
                                    </Nav>
                                }
                                {participant.list.isLoaded && (
                                    <div>
                                        <UserAvatar
                                            openAvatarMenu={openAvatarMenu}
                                            participant={participant.list.data}
                                        />
                                    </div>
                                )}

                                {(!(participant.list.isLoaded && Object.keys(participant.list.data).length > 0)) &&
                                    auth.preRegister.registerTypeInformations.list.isLoaded && (
                                        // !auth.preRegister.registerTypeInformations.list.data.preRegistration.autoGenerateAccessInfo && (
                                        <>
                                            {(auth.preRegister.registerTypeInformations.list?.data?.preRegistration?.enabled ||
                                                auth.preRegister.registerTypeInformations.list?.data?.preRegistration?.participantCreateAccessInfo
                                            ) && (
                                                    <a
                                                        style={{
                                                            marginRight: navVisibility ? '0' : '0.5rem',
                                                            marginBottom: (navVisibility && auth.preRegister.registerTypeInformations.list.data.allowExternalRegistration.enabled) ? '1rem' : '0',
                                                            textDecoration: 'none',
                                                        }}
                                                        // href={auth.preRegister.registerTypeInformations.list?.data?.preRegistration?.participantCreateAccessInfo ?
                                                        //     `https://${getSubdomain}.h1.campanhasonline.com.br${location.getFirstPathname()}/portal/auth/pre/create-access-email${campaignPath ? '?campaign=' + campaignPath : ''}` :
                                                        //     `https://${getSubdomain}.h1.campanhasonline.com.br${location.getFirstPathname()}/portal/auth/pre${campaignPath ? '?campaign=' + campaignPath : ''}`}
                                                        href={auth.preRegister.registerTypeInformations.list?.data?.preRegistration?.participantCreateAccessInfo ?
                                                            location.redirectPortal({ pathTo: '/portal/auth/pre/create-access-email' }) :
                                                            location.redirectPortal({ pathTo: '/portal/auth/pre' })}
                                                        rel='noreferrer'>
                                                        <Button
                                                            withBorder={true}
                                                            sx={{
                                                                buttonBorderColor: custom.buttonAccessBorderColor,
                                                                buttonColor: custom.buttonAccessColor,
                                                                buttonBgColor: 'transparent'
                                                            }}
                                                            text="Primeiro acesso"
                                                            onClick={() => {
                                                                handleMenu();
                                                            }}
                                                        />

                                                    </a>
                                                )}
                                            {auth.preRegister.registerTypeInformations.list.data.allowExternalRegistration.enabled && (
                                                    <a
                                                        style={{ marginRight: navVisibility ? '0' : '0.5rem', textDecoration: 'none', }}
                                                        // href={`https://${getSubdomain}.h1.campanhasonline.com.br/portal/auth/pre/open${campaignPath ? '?campaign=' + campaignPath : ''}`}
                                                        href={location.redirectPortal({ pathTo: '/portal/auth/pre/open' })}
                                                        rel='noreferrer'>
                                                        <Button
                                                            withBorder={true}
                                                            sx={{
                                                                buttonBorderColor: custom.buttonAuthBorderColor,
                                                                buttonColor: custom.buttonAuthColor,
                                                                buttonBgColor: 'transparent'
                                                            }}
                                                            text="Criar conta"
                                                            onClick={() => {
                                                                handleMenu();
                                                            }}
                                                        />
                                                    </a>
                                                )}
                                        </>
                                    )
                                }
                                {!(participant.list.isLoaded && Object.keys(participant.list.data).length > 0) && (
                                    <a
                                        style={{
                                            textDecoration: 'none',
                                            marginTop: navVisibility ? '1rem' : 0
                                        }}
                                        // href={`https://${getSubdomain}.h1.campanhasonline.com.br/portal/auth/login${campaignPath ? '?campaign=' + campaignPath : ''}`}
                                        href={location.redirectPortal({ pathTo: '/portal/auth/login' })}
                                        rel='noreferrer'>
                                        <Button
                                            withBorder={true}
                                            link={'#!'}
                                            sx={{
                                                buttonBorderColor: custom.buttonAuthBorderColor,
                                                buttonColor: custom.buttonAuthColor,
                                                buttonBgColor: 'transparent'
                                            }}
                                            text="Já tenho conta"
                                            onClick={() => {
                                                handleMenu();
                                            }}
                                        />
                                    </a>
                                )}
                            </Column>

                        </Columns>
                    </Container>
                </Main>

                <Dialog ref={contactRef} desactivateLink={() => null}>
                    <ContactForm
                        backgroundColorButton={color.indigo[700]} fontColorButton={color.common.white}
                    />
                </Dialog>

                <Dialog
                    ref={termsRef} title="Regulamento"
                >
                    <Regulament />
                </Dialog>
            </div>
        )
    }

export { Header };
