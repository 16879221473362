/* eslint-disable no-useless-escape */
/* eslint-disable valid-jsdoc */
import { CONSTANTS } from '../constants';
import configs from '../config/config.json';
const { SERVER_DOMAINS } = CONSTANTS;

/**
 *  returns only the domain of a URL.
 *
 *   -> input: `https://commarket.h1.campanhasonline.com.br/portal/auth/login`
 *
 *   -> output: `commarket.h1.campanhasonline.com.br`
 */
function getDomain() {
    const url = window.location.href;
    // Regular expression to capture the domain with subdomain
    const regex = /^(?:https?:\/\/)?([^:\/\n?]+)/img;
    const result = regex.exec(url);
    const domainWithSubdomain = result ? result[1] : null;
    return domainWithSubdomain;
}

/**
 *  returns only the subdomain of a url.
 *
 *   -> input: `https://commarket.h1.campanhasonline.com.br/portal/auth/login`
 *
 *   -> output: `commarket`
 */
function getSubdomain() {
    const url = window.location.href;
    // Regular expression to capture the subdomain
    const regex = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/img;
    const result = regex.exec(url);
    const subdomain = result ? result[1].split('.')[0] : null;
    return subdomain;
}


/**
 *  returns only the first pathname of a URL.
 *
 *   -> input: `https://commarket.h1.campanhasonline.com.br/portal/auth/login`
 *
 *   -> output: `portal`
 */
function getFirstPathname() {
    const url = window.location.href;
    // Check if the URL is valid
    if (!url || typeof url !== 'string') {
        return null;
    }

    // Remove protocol and domain from the URL
    const path = url.split('//')[1]?.split('/')[1];

    const firstPathname = path ? path.split('/')[0] : null;

    if (firstPathname?.indexOf('#') !== -1) {
        return firstPathname?.split('#')[0]
    }

    // Get only the first pathname

    return firstPathname;
}
/**
 *  returns only the first pathname of a URL.
 *
 *   -> input: `https://commarket.h1.campanhasonline.com.br/portal/auth/login`
 *
 *   -> output: `auth`
 */
function getSecondPathname() {
    let pathname = "";
    const url = window.location.href;
    // Check if the URL is valid
    if (!url || typeof url !== 'string') {
        return null;
    }


    // Remove protocol and domain from the URL
    const path = url.split('//')[1]?.split('/')[2];

    // Get only the first pathname
    const secondPathname = path ? path.split('/')[0] : null;

    if (secondPathname && secondPathname?.indexOf("#") !== -1) {
        const [lastPathname] = secondPathname.split("#");
        pathname = lastPathname;
    } else {
        pathname = secondPathname ? secondPathname : '';
    }

    return pathname;
}

const filesPath = (concatFile: string) => {
    const { DOMAINS, ENDPOINTS } = CONSTANTS.SERVICES_APIS_DOMAINS;

    let apiURL = '';
    if (configs.serverURL.indexOf(DOMAINS.LOCAL) !== -1) {
        apiURL = DOMAINS.LOCAL + ENDPOINTS.LOCAL.CAMPAIGN;
    }
    if (configs.serverURL.indexOf(DOMAINS.HML) !== -1) {
        apiURL = configs.serverURL;
    }
    if (configs.serverURL.indexOf(DOMAINS.PROD) !== -1) {
        apiURL = configs.serverURL;
    }
    return apiURL + concatFile;
};

const getCampaignDomain = () => {
    if (window.location.href.indexOf('campaign=') > -1) {
        const splitArray = window.location.href.split('campaign=');
        const lastElement = splitArray[splitArray.length - 1];
        return lastElement;
    }
};

interface RedirectPortalProps {
    // websiteAliasCompany: string;
    // websiteAliasProgram: string;
    // websiteAliasCampaign: string;
    pathTo?: string;
}

const redirectPortal = ({ pathTo }: RedirectPortalProps) => {
    let apiURL = "";
    const aliasCompany = getSubdomain();
    const aliasProgram = getFirstPathname();
    const aliasCampaign = getSecondPathname();

    const campaignParam = `${pathTo ? pathTo + (aliasCampaign ? "?campaign=" + aliasCampaign : '') : ""}`;
    if (configs.serverURL.indexOf(SERVER_DOMAINS.LOCAL) !== -1) {
        apiURL = `${SERVER_DOMAINS.LOCAL}/${aliasProgram}${campaignParam}`;
        return apiURL;
    }
    if (configs.serverURL.indexOf(SERVER_DOMAINS.HML) !== -1) {
        apiURL = `https://${aliasCompany}${SERVER_DOMAINS.HML}/${aliasProgram}${campaignParam}`;
        return apiURL;
    }
    if (configs.serverURL.indexOf(SERVER_DOMAINS.PROD) !== -1) {
        apiURL = `https://${aliasCompany}${SERVER_DOMAINS.PROD}/${aliasProgram}${campaignParam}`;
        return apiURL;
    }
    return apiURL;
}

export const location = {
    getDomain,
    getSubdomain,
    getFirstPathname,
    getSecondPathname,
    filesPath,
    getCampaignDomain,
    redirectPortal,
};

