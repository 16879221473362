import { library } from '@fortawesome/fontawesome-svg-core'

import { faChevronLeft, faChevronRight, faTimes, faLongArrowRight, faBraille, faSmileBeam, faArrowLeft, faCheck, faSpinnerThird, faShare } from "@fortawesome/pro-regular-svg-icons";

import { faPlusCircle, faMinusCircle, faChevronDoubleRight, faBars, faCheckCircle, faCaretUp, faCaretDown } from "@fortawesome/pro-solid-svg-icons";

import { faSignIn, faExclamationTriangle, faArrowCircleLeft, faInfoCircle } from "@fortawesome/pro-light-svg-icons";


library.add(
    faSpinnerThird,
    faCheck,
    faCheckCircle,
    faChevronLeft,
    faChevronRight,
    faPlusCircle,
    faMinusCircle,
    faChevronDoubleRight,
    faTimes,
    faLongArrowRight,
    faBars,
    faSignIn,
    faBraille,
    faSmileBeam,
    faArrowLeft,
    faExclamationTriangle,
    faArrowCircleLeft,
    faInfoCircle,
    faCaretUp,
    faCaretDown,
    faShare,
);