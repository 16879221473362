import { svg } from './SvgIcon';
import { isObject } from './isObject';
import { recursiveFindInObject } from './recursiveFindInObject';
import { useClickOutside } from './useClickOutside';
import { maxText } from './maxTextLength';
import { location } from './location';
import { scrollToSection } from './scrollToSection';
import { NavDataProps } from '@interfaces/theme/hotsite/TextsJSON';

const menu = (
    navData: NavDataProps[],
    filterBy: string,
    contactFormIsEnabled: boolean,
    section1Visibility: "true" | "false" | undefined,
    textMenu: string
  ) => {
    const tempMenu: NavDataProps[] = [];
    const dataMenu: NavDataProps[] = [];

    if (contactFormIsEnabled) {
      tempMenu.push(...navData);
    } else {
      tempMenu.push(...navData
        // Fale conosco id = 4
        .filter(({ id }) => id !== '4'));
    }

    if (section1Visibility !== 'false') {
      const filter = tempMenu.filter(({ link }) => link !== filterBy);
      const newMenuItem = {
        id: '2',
        link: '#1',
        title: textMenu, // campaign.theme.texts.data.section1.section1Headline.section1HeadlineText,
        _id: '666b3e7562f23fa6e7f391ba',
      };

      dataMenu.push(newMenuItem, ...filter);
    }

    return dataMenu;
  };

export {
    svg,
    isObject,
    recursiveFindInObject,
    useClickOutside,
    location,
    maxText,
    scrollToSection,
    menu,
}